<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import businessInsiderUrl from '@/images/landing-page/business-insider.png'
  import foxNewsUrl from '@/images/landing-page/fox-news.png'
  import PCMag from '@/images/landing-page/pc-mag.png'
  import UsaToday from '@/images/landing-page/usa-today.png'
  import engadgetUrl from '@/images/landing-page/engadget.png'

  export let invert = false

  const asSeenOnLogos = [
    { url: PCMag, altText: 'PC Magazine' },
    { url: foxNewsUrl, altText: 'Fox News' },
    { url: UsaToday, altText: 'USA today' },
    { url: engadgetUrl, altText: 'Engadget' },
    { url: businessInsiderUrl, altText: 'Business Insider' },
  ]
</script>

<Section
  maxWidth="2xl"
  background="none"
  verticalPadding="none"
  horizontalPadding="none"
  center={true}
>
  <h2 class="font-extrabold">50,000+ 5-STAR REVIEWS</h2>

  <div
    class="flex gap-2 md:gap-8 w-full items-center justify-center flex-wrap pt-6 pb-11 md:py-11 {invert
      ? 'bg-black-200'
      : ''}"
  >
    {#each asSeenOnLogos as logo}
      <div class="">
        <img
          src={logo.url}
          alt={logo.altText}
          class="h-8 md:h-[72px]"
          class:invert
        />
      </div>
    {/each}
  </div>
</Section>
