<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  export let darkTheme = false
</script>

<Section
  verticalPadding="none"
  horizontalPadding="none"
  center={true}
  maxWidth="xl"
>
  <div class="text-center md:mt-6 leading-tight mb-4">
    <div class="sm:inline-block bg-green px-4 py-2">
      <p
        class="text-center font-semibold"
        class:text-white={!darkTheme}
        class:text-black={darkTheme}
      >
        DON'T LET YOUR MECHANIC TAKE ADVANTAGE OF YOU!
      </p>
    </div>
  </div>

  <h1 class="text-40 md:text-[96px] font-extrabold uppercase">get fixd</h1>

  <h2 class="font-semibold px-4">
    <span class="text-green">The</span> “secret weapon”
    <span class="text-green">mechanics don't want you to know about...</span>
  </h2>

  <div class="my-6">
    <div class="relative pb-[56.25%]">
      <iframe
        src="https://www.youtube.com/embed/t0OPL0sOHDI?si=LARE7UpkDEl5QMu-"
        title="YouTube video player"
        frameborder="0"
        class="absolute top-0 left-0 w-full h-full"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        allowfullscreen
      />
    </div>
  </div>

  <div class="md:w-3/5 mx-auto px-4">
    <p class="text-xl font-semibold mb-4">
      The ONLY device that can save you $1,000s in car repair and maintenance
      costs... for less than the cost of one oil change.
    </p>

    <p class="text-sm font-medium">
      From diagnosing “check engine” lights to quoting repair costs, many
      mechanics charge far more than they should. Meet the device that not only
      diagnoses problems for you, but tells you how much you should pay for
      repairs and maintenance.
    </p>
  </div>

  <slot name="cta" />
</Section>
