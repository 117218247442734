<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { FaStarFilledRound } from '@/components/icons'
  export let header = `“FIXD IS A MONEY-SAVER, HANDS DOWN.”`
  export let showQuotation = true
</script>

<Section maxWidth="lg">
  <div class="text-center">
    <div class="mb-8">
      {#each new Array(5) as _}
        <FaStarFilledRound color="yellow" size="xl" />
      {/each}
    </div>

    <div class="flex justify-center md:gap-6">
      {#if showQuotation}
        <div class="self-start">
          <p
            class="font-bold text-[128px] hidden md:block text-[#647581] -mt-14"
          >
            “
          </p>
        </div>
      {/if}

      <div>
        <p class="font-extrabold text-2xl md:text-4xl mb-6">
          {header}
        </p>
        <p class="text-xl leading-tight">
          <slot name="description" />
        </p>
      </div>

      {#if showQuotation}
        <p
          class="font-bold text-[128px] rotate-180 hidden md:block text-[#E0E1E7] -mt-14"
        >
          “
        </p>
      {/if}
    </div>
  </div>
</Section>
