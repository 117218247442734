<script>
  import HeroImage from '@/images/landing-page/scorpio/io/everything-desktop.png'
  import HeroMobileImage from '@/images/landing-page/scorpio/io/everything-mobile.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<Section horizontalPadding="none" verticalPadding="none" maxWidth="2xl">
  <div class="relative hidden md:block">
    <div class="mt-9 absolute top-0 left-0 w-full text-center">
      <h2 class="text-white">Everything You're Getting:</h2>
    </div>
    <img src={HeroImage} alt="everything you are getting" />
    <div class="bg-[#000000] pt-2 pb-10">
      <slot name="cta" />
    </div>
  </div>

  <div class="bg-[#000000] md:hidden">
    <h1
      class="text-white py-9 px-10 text-center text-36 font-extrabold leading-tight"
    >
      Everything You're Getting:
    </h1>
    <img src={HeroMobileImage} alt="everything you are getting" />

    <div class="pt-6 pb-6">
      <ul class="text-white text-base font-extrabold px-6">
        <li>
          &bull; Engine Diagnostic Report <br /><span class="pl-3 text-red"
            >($150 value)</span
          >
        </li>
        <li>&bull; Issue Severity Rating</li>
        <li>&bull; Clear Warning Light</li>
        <li>&bull; Automatic Maintenance Alerts</li>
        <li>&bull; Repair Shop Finder</li>
        <li>&bull; Live OBD2 Data</li>
        <li>&bull; DIY Tutorials</li>
        <li>&bull; Vehicle Market Value Report</li>
        <li>&bull; Recall Alerts</li>
        <li>&bull; Confirmed Fix & Cost</li>
        <li>
          &bull; FIXD Mechanic Hotline <br /><span class="pl-3 text-red"
            >($40 value)</span
          >
        </li>
        <li>&bull; 24/7 AI Mechanic</li>
        <li>&bull; Issue Forecast</li>
        <li>
          &bull; Used Vehicle History Report <br /><span class="pl-3 text-red"
            >($40 value)</span
          >
        </li>
        <li>&bull; Emissions Precheck</li>
      </ul>
    </div>
    <div class="text-white text-center px-6 leading-tight pb-10">
      <p class="text-30 font-extrabold">
        TOTAL VALUE: <span class="text-red strikethrough">$388</span>
      </p>
      <p class="text-30 font-extrabold">
        TODAY ONLY: <span class="text-green">$19.99</span>
      </p>
    </div>
  </div>
</Section>
