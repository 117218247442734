<script>
  import LiveReviews from '@/components/landing-page/LiveReviews.svelte'

  import Footer from '@/components/landing-page/Footer.svelte'
  import AsSeenOn from '@/pages/scorpio/components/io/02AsSeenOn.svelte'
  import HowItWorks from '@/pages/scorpio/components/io/06HowItWorks.svelte'
  import QuizCompatibility from '@/pages/scorpio/QuizCompatibility.svelte'

  import Benefits from '@/pages/scorpio/components/Benefits.svelte'
  import BuyBox from '@/pages//scorpio/components/L007/BuyBoxL007.svelte'
  import FAQ from '@/pages/scorpio/components/io/11FAQ.svelte'

  import JumpToBuyBoxButton from '@/components/landing-page/widgets/JumpToBuyBoxButton.svelte'

  export let funnel
  export let gateway

  $: completedQuiz = false
  $: startedQuiz = false

  $: if (completedQuiz) {
    setTimeout(() => {
      document.querySelector('.buy-box').scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }, 1500)
  }
</script>

<main class="md:pb-0">
  {#if !completedQuiz || startedQuiz}
    <div class="bg-black-0">
      <QuizCompatibility bind:completedQuiz bind:startedQuiz />
      {#if !completedQuiz}
        <Footer background="dark" additionalClass="bg-black-0" />
      {/if}
    </div>
  {/if}
  {#if startedQuiz}
    <div class:hidden={!completedQuiz}>
      <div class="max-w-[1440px] mx-auto mt-12 md:mt-20">
        <h1 class="md:hidden font-extrabold text-center mb-4">
          Try FIXD And Get $388 In Money-Saving Car Features For Only $19.99!
        </h1>
        <BuyBox {funnel} {gateway} />
      </div>

      <Benefits />

      <HowItWorks>
        <div class="flex justify-center w-full" slot="cta">
          <div class="max-w-max">
            <JumpToBuyBoxButton size="full">
              <span class="uppercase text-xl">Get $230+ in savings</span>
            </JumpToBuyBoxButton>
          </div>
        </div>
      </HowItWorks>

      <AsSeenOn />

      <LiveReviews backgroundColor="white">
        <div slot="header">
          <div class="text-center">
            <h2 class="font-extrabold text-4xl">
              See Why Millions of Drivers Love FIXD
            </h2>
          </div>
        </div>
      </LiveReviews>

      <FAQ background="bg-grey-200" />

      <Footer />
    </div>
  {/if}
</main>
