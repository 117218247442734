<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import Image from '@/components/Image.svelte'

  import phoneImg from '@/images/landing-page/Sensor67/white_phone_and_sensor_transparent.png?width=500&as=picture'
  import iconCarsURL from '@/images/landing-page/Sensor67/Cars.png?w=70'
  import iconCelURL from '@/images/landing-page/Sensor67/OrangeEngine.png?w=70'
  import iconCheckURL from '@/images/landing-page/Sensor67/GreenCheckMark.png?w=70'
  import iconWirelessURL from '@/images/landing-page/Sensor67/wireless.png?w=70'
  import iconWrenchesURL from '@/images/landing-page/Sensor67/RedWrench.png?w=70'
  import iconDetailsURL from '@/images/landing-page/Sensor67/Magnify.png?w=70'

  const benefits = [
    {
      icon: iconCelURL,
      title: 'Check Engine',
      body: `Check engine light? FIXD instantly tells you in-depth what's wrong in plain, simple terms right on your smartphone.`,
    },
    {
      icon: iconWrenchesURL,
      title: 'Issue Severity',
      body: `Find out how serious your issue is so you know whether or not it's safe to continue driving. You can even turn off the light if it's nothing serious!`,
    },
    {
      icon: iconCheckURL,
      title: 'Maintenance Tracker',
      body: `Our algorithm tracks your mileage and automatically notifies you when it's time for routine maintenance so you can avoid costly repairs and breakdowns.`,
    },
    {
      icon: iconDetailsURL,
      title: 'Detailed Diagnostics',
      body: `Unlike other OBD2 sensors, FIXD provides detailed, easy-to-understand information to help you feel confident at the repair shop or even complete repairs yourself.`,
    },
    {
      icon: iconCarsURL,
      title: 'Multi-Vehicle Control',
      body: `Monitor all your vehicles from one account, making it easy to schedule services for family members even if you're not there!`,
    },
    {
      icon: iconWirelessURL,
      title: 'Avoid Costly Repairs',
      body: `FIXD automatically syncs to your smartphone via Bluetooth while you're driving so that you can continuously monitor your car's health and quickly resolve any issues.`,
    },
  ]
</script>

<Section center="true" maxWidth="xl">
  <slot name="header">
    <h2 class="font-semibold mb-1">
      Here's why FIXD is more than just another code reader
    </h2>
  </slot>

  <div class="max-w-screen-md mx-auto">
    <p class="mb-8">
      FIXD not only gives you access to detailed engine information you can use
      to be more informed at the mechanic, but it also includes powerful
      features to help you save money and avoid costly repairs and breakdowns…
    </p>
  </div>

  <div
    class="benefits text-center flex flex-col md:flex-row flex-nowrap items-center"
  >
    <div class="group flex-1">
      {#each benefits.slice(0, 3) as benefit}
        <div class="mb-2 md:mb-7">
          <div
            class="flex flex-col md:flex-row flex-nowrap gap-4 mb-2.5 text-center md:text-left"
          >
            <div class="shrink-0 flex-1">
              <img
                src={benefit.icon}
                alt={benefit.title}
                class="max-w-[70px]"
              />
            </div>
            <div>
              <h3 class="text-xl mb-1">{benefit.title}</h3>
              <p class="text-center font-semibold md:text-left">
                {benefit.body}
              </p>
            </div>
          </div>
        </div>
      {/each}
    </div>

    <div class="flex flex-col flex-1 flex-nowrap self-start md:self-center">
      <Image
        image={phoneImg}
        alt="The FIXD app and sensor"
        --width="100%"
        media={{ default: { '1x': 500, '2x': 1000 } }}
      />
    </div>

    <div class="group flex-1">
      {#each benefits.slice(3, 6) as benefit}
        <div class="mb-2 md:mb-7">
          <div
            class="flex flex-col md:flex-row flex-nowrap gap-4 mb-2.5 text-center md:text-left"
          >
            <div class="shrink-0 flex-1">
              <img
                src={benefit.icon}
                alt={benefit.title}
                class="max-w-[70px]"
              />
            </div>
            <div>
              <h3 class="text-xl mb-1">{benefit.title}</h3>
              <p class="text-center font-semibold md:text-left">
                {benefit.body}
              </p>
            </div>
          </div>
        </div>
      {/each}
    </div>
  </div>

  <slot name="cta" />
</Section>
