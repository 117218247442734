<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import foundersImg from '@/images/landing-page/webflow/founders.jpg?as=picture'
  import Image from '@/components/Image.svelte'
</script>

<Section center="true" maxWidth="xl">
  <div class="md:flex items-center">
    <h1 class="block md:hidden text-center md:text-left mb-8">
      The Story Behind FIXD
    </h1>

    <div class="basis-1/2 w-full">
      <Image image={foundersImg} alt="The founders" class="w-full" />
    </div>
    <div class="text-left basis-1/2 md:pl-5 px-2">
      <div class="my-4">
        <h1 class="hidden md:block text-center md:text-left">
          The Story Behind FIXD
        </h1>
      </div>
      <div class="flex gap-4 flex-col font-medium text-xl leading-tight">
        <p>
          FIXD was founded by 3 Georgia Tech grads: John Gattuso, Julian Knight,
          and Frederick Grimm.
        </p>

        <p>
          The trio grew up working on cars, and were known among all their
          friends and family as 'The Car Guys'. People would always call them
          when they had car problems, and then call again after they visited a
          mechanic to see if they were getting a fair price.
        </p>
        <p>
          “We know that horrible sense of dread and anxiety you feel at the
          mechanic when you have to ask yourself 'How much will this repair
          cost? How will I know if I'm paying an honest price - or getting
          ripped off?'
        </p>
        <p>
          These requests kept coming — so they decided to create a way to
          empower their friends and family to better understand their car (and
          ensure they never got ripped off by a mechanic). Over the next two
          years they designed and built a device that would easily translate car
          problems into simple terms for drivers: the FIXD sensor.
        </p>
      </div>
    </div>
  </div>
</Section>
