<script>
  import HeroImage from '@/images/landing-page/scorpio/io/hero.png'
  import HeroImage2 from '@/images/landing-page/scorpio/io/hero2.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { Logo, FixdLogoText, FaStarFilledRound } from '@/components/icons'
</script>

<style>
  .title {
    text-shadow: 4px 4px 8.4px rgb(0 0 0 / 67%);
  }

  .container {
    background: rgb(0 0 0 / 30%);
  }

  @media only screen and (width >= 768px) {
    .title {
      text-shadow: none;
    }
  }
</style>

<div class="hidden md:block">
  <Section horizontalPadding="none" verticalPadding="none" maxWidth="2xl">
    <div
      class="hero-container h-[374px] md:h-[584px] w-full bg-no-repeat md:bg-center bg-cover"
      style="background-image: url({HeroImage2});
"
    >
      <div class="relative">
        <div class="absolute top-0 left-0 w-full my-5">
          <div class="flex items-center gap-2 justify-center mb-10 md:mt-4">
            <Logo color="white" size="sm" />
            <FixdLogoText color="white" size="sm" />
          </div>
        </div>

        <div class="flex justify-end h-full md:h-[584px] items-center">
          <div
            class="w-full md:w-7/12 text-white py-6 md:p-5 md:rounded-3xl leading-tight"
          >
            <h1
              class="text-48 text-center md:text-left font-semibold px-4 md:px-0 title"
            >
              Get $388 in money-saving car features for only $19.99!
            </h1>

            <div class="hidden md:block">
              <p class="py-4 text-lg">
                Try FIXD risk-free today and get instant access to 15 features
                designed to save you $1000s on car repairs.
              </p>

              <div class="mt-7">
                <slot name="cta" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
</div>

<div class="md:hidden">
  <Section horizontalPadding="none" verticalPadding="none" maxWidth="2xl">
    <div
      class="hero-container h-[374px] md:h-[584px] w-full bg-no-repeat md:bg-center bg-[60%] bg-cover"
      style="background-image: url({HeroImage});
"
    >
      <div class="flex items-center gap-2 justify-center mb-10 pt-4">
        <Logo color="white" size="sm" />
        <FixdLogoText color="white" size="sm" />
      </div>

      <div
        class="w-full container md:w-5/12 text-white pb-10 pt-20 -mt-10 md:p-5 md:rounded-3xl md:ml-[10%]"
      >
        <h1
          class="text-40 text-center md:text-left font-semibold mt-10 px-4 md:px-0 title leading-[42px]"
        >
          Get $388 in money-saving car features for only $19.99!
        </h1>
      </div>
    </div>
  </Section>
</div>

<div class="block md:hidden font-semibold">
  <Section verticalPadding="none">
    <p class="py-4 font-semibold">
      Try FIXD risk-free today and get a free trial of FIXD Premium. You'll get
      instant access to our most advanced engine diagnostics, live Mechanic
      Hotline, cost estimates, and more for the next 14 days - all for less than
      a fast food dinner for two!
    </p>

    <div class="text-center">
      <div class="inline-flex items-center">
        {#each new Array(5) as _}
          <FaStarFilledRound color="black" size="sm" />
        {/each}
        <p class="pl-1">50,000+ Reviews</p>
      </div>
    </div>

    <div class="mt-7 mb-13">
      <slot name="cta" />
    </div>
  </Section>
</div>
