<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import Phone2 from '@/images/landing-page/scorpio/io/phone2.png'
  import Phone3 from '@/images/landing-page/scorpio/io/phone3.png'
  import Phone4 from '@/images/landing-page/scorpio/io/phone4.png'
  import Phone5 from '@/images/landing-page/scorpio/io/phone5.png'

  const images = [
    {
      img: Phone2,
      title: 'Diagnose Car Problems in Seconds',
      description:
        "Most mechanics charge $150 to diagnose a single check engine light. With FIXD's detailed Diagnostic Report, you can quickly and accurately diagnose car problems yourself, saving time and money.",
    },
    {
      img: Phone3,
      title: "Know If It's Safe to Drive",
      description:
        "Wondering if it's safe to keep driving with your check engine light? Find out in seconds with Issue Severity.",
    },
    {
      img: Phone4,
      title: 'Clear Your Check Engine Light',
      description:
        "You can turn off your check engine light from your phone if it's nothing serious or you're troubleshooting repairs.",
    },
    {
      img: Phone5,
      title: 'Never Miss an Oil Change',
      description:
        'Prevent costly repairs and keep your car running smoothly with automatic maintenance alerts that let you know when your car is due for service!',
    },
  ]
</script>

<Section center="true" maxWidth="2xl" verticalPadding="none">
  <div class="py-11 md:px-16">
    <div class="md:mb-20 md:mt-10 leading-tight">
      <h1 class="font-extrabold hidden md:block">
        Here's What You Get When You Try FIXD Today:
      </h1>
      <h1 class="font-extrabold md:hidden">Instantly Diagnose Car Problems</h1>
    </div>

    <div class="md:flex flex-wrap hidden">
      {#each images as image}
        <div class="basis-1/2 text-center mb-10">
          <img src={image.img} alt={image.title} />
          <div class="my-6">
            <p class="font-bold text-xl">{image.title}</p>
          </div>
          <p class="font-medium text-xl px-10">
            {image.description}
          </p>
        </div>
      {/each}
    </div>

    <div class="md:hidden">
      <Carousel
        showPagination={true}
        loop={true}
        splideOptions={{
          autoHeight: true,
          lazyLoad: true,
        }}
      >
        {#each images as image}
          <CarouselItem>
            <div class="text-center">
              <img src={image.img} alt={image.title} loading="lazy" />
              <div class="text-xl leading-tight mt-10 mx-auto w-full md:w-4/5">
                <p class="font-bold">{image.title}</p>
                <p class="font-medium">
                  {image.description}
                </p>
              </div>
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
    <div class="mt-10">
      <slot name="cta" />
    </div>
  </div>
</Section>
