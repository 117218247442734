<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import TapToScanImage from '@/images/landing-page/scorpio/lp-tap-to-scan.png'
  import SensorImage from '@/images/landing-page/scorpio/lp-3d-sensor.png'
  import WifiIcon from '@/images/landing-page/scorpio/lp-wifi.png'

  import {
    Wrench,
    Engine,
    UserIcon,
    CalendarBell,
    CarConnect,
    Card,
  } from '@/components/icons'
</script>

<style>
  .background-gradient {
    background: rgb(63 82 92);
    background: radial-gradient(
      circle,
      rgb(63 82 92 / 100%) 0%,
      rgb(34 34 34 / 100%) 60%
    );
  }
</style>

<div class="background-gradient py-15 text-white">
  <Section maxWidth="xl" verticalPadding="none">
    <div class="leading-tight text-center max-w-screen-md mx-auto mb-6">
      <h2 class="mb-7 md:mb-1">
        Here's why FIXD is more than just another code reader
      </h2>
      <p class="font-medium">
        Unlike other OBD2 scanners, FIXD doesn't just spit out the definition of
        your code. It tells you in simple terms what your check engine light
        means, how serious it is, and ways to fix it. Plus, it's packed with
        powerful features that will boost your confidence at the shop and save
        you money!
      </p>
    </div>
    <div
      class="text-center md:text-left flex flex-col md:flex-row flex-nowrap lg:px-10"
    >
      <div class="flex flex-col flex-nowrap basis-full px-2.5 lg:pr-3">
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <UserIcon color="white" size="2xl" class="size-12" />
            <div class="md:w-[250px]">
              <h3 class="text-xl m-0 font-extrabold">Easy to use</h3>
              <p class="font-semibold mt-1">
                Translates over 7000 issues into easy-to-understand terms, right
                on your phone. No car knowledge needed.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <Engine color="white" size="2xl" class="size-12" />
            <div class="md:w-[250px]">
              <h3 class="text-xl m-0 font-extrabold">Instantly Clear Codes</h3>
              <p class="font-semibold mt-1">
                Clear check engine lights with the touch of a button and keep a
                record of past vehicle scans inside the FIXD app.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <Card color="white" size="2xl" class="size-12" />
            <div class="md:w-[250px]">
              <h3 class="text-xl m-0 font-extrabold">Never Overpay Again</h3>
              <p class="font-semibold mt-1">
                Get detailed information you can take straight to the mechanic
                to ensure you're getting a fair deal.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex basis-full md:mt-10 relative h-full self-center mb-10">
        <div class="mt-32 md:mt-0 -mr-6 md:mr-0 md:-ml-4 rotate-[-15deg]">
          <img
            src={TapToScanImage}
            alt="tap to scan"
            width="200"
            class="w-full h-auto max-w-[200px]"
            height="360"
          />
        </div>
        <div class="xl:absolute top-0 right-0">
          <div class="relative">
            <img
              src={WifiIcon}
              alt="wifi"
              width="46"
              height="40"
              class="absolute top-4 left-[-40px] w-full h-auto max-w-[46px]"
            />
            <img
              src={SensorImage}
              alt="sensor"
              width="162"
              height="215"
              class="w-full h-auto max-w-[162px]"
            />
          </div>
        </div>
      </div>

      <div class="flex flex-col flex-nowrap basis-full px-2.5 lg:pl-3">
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <CalendarBell color="white" size="2xl" class="size-14" />
            <div class="md:w-[250px]">
              <h3 class="text-xl m-0 font-extrabold">Maintenance Alerts</h3>
              <p class="font-semibold mt-1">
                FIXD automatically alerts you when your vehicle is due for
                service so you can save on costly repairs and keep it running
                smoothly.
              </p>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <Wrench color="white" size="2xl" class="size-14" />
            <div class="md:w-[250px]">
              <h3 class="text-xl m-0 font-extrabold">
                Prevent Costly Breakdowns
              </h3>
              <p class="font-semibold mt-1">
                Monitor all your vehicles from one account, making it easy to
                stay on top of car health and avoid costly repairs.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <CarConnect color="white" size="3xl" class="size-14" />
            <div class="md:w-[250px]">
              <h3 class="text-xl m-0 font-extrabold">Real-Time Monitoring</h3>
              <p class="font-semibold mt-1">
                Continuously monitor your vehicle's performance, easily
                troubleshoot systems, and stress less with live engine data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
</div>
