<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import { Chain, ShoppingCartSolid } from '@/components/icons'

  import SmileImage from '@/images/landing-page/scorpio/L017/smile.png'

  export let darkTheme = false
</script>

<Section
  center="true"
  maxWidth="xl"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 px-3 md:px-0 {darkTheme ? 'bg-[#000000]' : 'bg-grey-200'}">
    <div class="max-w-screen-lg mx-auto">
      <h1 class="text-22 md:text-4xl font-bold mb-10">
        Peace of Mind in 3 Easy Steps
      </h1>
      <div class="flex flex-col md:flex-row mb-7 gap-12 md:gap-8">
        <div class="text-center leading-tight flex flex-col items-center">
          <div
            class="rounded-full size-[112px] bg-green flex items-center justify-center mb-6"
          >
            <ShoppingCartSolid color="white" class="size-14" />
          </div>
          <p class="font-extrabold mb-4">1. ORDER</p>

          <p>Order your FIXD Sensor + Premium App today - Shipping is FREE!</p>
        </div>
        <div class="text-center leading-tight flex flex-col items-center">
          <div
            class="rounded-full size-[112px] bg-green flex items-center justify-center mb-6"
          >
            <Chain color="white" class="size-15" />
          </div>
          <p class="font-extrabold mb-4">2. CONNECT</p>

          <p>
            Connect your smartphone to your car via the Bluetooth FIXD sensor.
          </p>
        </div>
        <div class="text-center leading-tight flex flex-col items-center">
          <div
            class="rounded-full size-[112px] bg-green flex items-center justify-center mb-6"
          >
            <img src={SmileImage} alt="smile" class="size-15" />
          </div>
          <p class="font-extrabold mb-4">3. RELAX</p>

          <p>
            Save time, money, and stress while keeping your car in top
            condition!
          </p>
        </div>
      </div>
    </div>
  </div>
</Section>
