<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import Phone2 from '@/images/landing-page/scorpio/io/phone6.png'
  import Phone3 from '@/images/landing-page/scorpio/io/phone7.png'
  import Phone4 from '@/images/landing-page/scorpio/io/phone8.png'
  import Phone5 from '@/images/landing-page/scorpio/io/phone9.png'

  const images = [
    {
      img: Phone2,
      title: 'Find Trusted Repair Shops',
      description:
        "Easily locate trustworthy, RepairPal Certified mechanics nationwide with FIXD's Repair Shop Finder.",
    },
    {
      img: Phone3,
      title: 'Get Real-Time Data',
      description:
        "Read your vehicle's live OBD2 data inside the app, helping you find the right fix and save money with at-home repairs!",
    },
    {
      img: Phone4,
      title: "See Your Car's Worth",
      description:
        'Thinking about selling your car? Get an instant Market Value Report and tips to help you get the most value from your sale.',
    },
    {
      img: Phone5,
      title: 'DIY Tutorials',
      description:
        'FIXD is packed with DIY guides and tutorials designed to help you fix your car yourself and save - even if you know nothing about cars!',
    },
  ]
</script>

<Section
  center="true"
  maxWidth="2xl"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 md:px-16 md:mb-10">
    <div class="mb-10 leading-tight">
      <h1 class="font-extrabold md:hidden">Save $1000s on Car Repairs</h1>
    </div>

    <div class="md:flex flex-wrap hidden">
      {#each images as image}
        <div class="basis-1/2 text-center mb-10">
          <img src={image.img} alt={image.title} />
          <div class="my-6">
            <p class="font-bold text-xl">{image.title}</p>
          </div>
          <p class="font-medium text-xl px-10">
            {image.description}
          </p>
        </div>
      {/each}
    </div>

    <div class="md:hidden">
      <Carousel
        showPagination={true}
        loop={true}
        splideOptions={{
          autoHeight: true,
          lazyLoad: true,
        }}
      >
        {#each images as image}
          <CarouselItem>
            <div class="text-center">
              <img src={image.img} alt={image.title} loading="lazy" />
              <div class="text-xl leading-tight mt-10 mx-auto w-full md:w-4/5">
                <p class="font-bold">{image.title}</p>
                <p class="font-medium">
                  {image.description}
                </p>
              </div>
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
    <div class="hidden md:block">
      <slot name="cta" />
    </div>
  </div>
</Section>
