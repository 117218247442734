<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import ThreeReasonsImage from '@/images/landing-page/scorpio/L017/3-reasons.png'
</script>

<Section center="true" horizontalPadding="none">
  <div class="flex flex-col-reverse md:flex-row">
    <div class="basis-1/2 text-left">
      <div class="md:w-4/5 md:mr-auto px-3">
        <div class="mb-6 mt-3">
          <p class="font-extrabold text-green">3 Reasons Why</p>
          <h2 class="font-extrabold">
            FIXD is America's Favorite Car Care Device.
          </h2>
        </div>
        <ul class="pl-4">
          <li>
            <span class="font-bold"
              >&bull; Find out what that check engine light means</span
            > in seconds… no mechanic and no diagnostic fee.
          </li>
          <li>
            <span class="font-bold">&bull; Get confirmed estimates</span> you can
            show your mechanic if they try to overcharge you.
          </li>
          <li>
            <span class="font-bold"
              >&bull; Get advice from an unbiased expert mechanic</span
            > before you pay a repair shop orr take on a DIY project.
          </li>
        </ul>
        <div class="mt-7">
          <slot name="cta" />
        </div>
      </div>
    </div>

    <div class="basis-1/2 mb-10 md:mb-0">
      <img src={ThreeReasonsImage} alt="Save $1843 on car maintenance" />
    </div>
  </div>
</Section>
