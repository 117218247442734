<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import businessInsiderUrl from '@/images/landing-page/business-insider.png'
  import TomsGuide from '@/images/landing-page/scorpio/premium-clarity/tom-guide.png'
  import foxNewsUrl from '@/images/landing-page/fox-news.png'
  import PCMag from '@/images/landing-page/pc-mag.png'
  import UsaToday from '@/images/landing-page/usa-today.png'
  import engadgetUrl from '@/images/landing-page/engadget.png'

  export let invert = false

  const asSeenOnLogos = [
    { url: TomsGuide, altText: 'Toms Guide' },
    { url: PCMag, altText: 'PC Magazine' },
    { url: foxNewsUrl, altText: 'Fox News' },
    { url: UsaToday, altText: 'USA today' },
    { url: engadgetUrl, altText: 'Engadget' },
    { url: businessInsiderUrl, altText: 'Business Insider' },
  ]
</script>

<Section
  maxWidth="2xl"
  background="none"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 {invert ? 'bg-[#2D2F2E]' : ''}">
    <slot name="header" />
    <div class="flex gap-8 w-full items-center justify-center flex-wrap">
      {#each asSeenOnLogos as logo}
        <div class="">
          <img
            src={logo.url}
            alt={logo.altText}
            class="h-8 md:h-16"
            class:invert
          />
        </div>
      {/each}
    </div>
  </div>
</Section>
