<script>
  import HeroImage from '@/images/landing-page/scorpio/io/tap-to-scan.png'
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<style>
  .background-container {
    background: rgb(16 16 17);
    background: linear-gradient(
      to bottom,
      rgb(16 16 17 / 34%) 0%,
      rgb(0 0 0 / 100%) 20%
    );
  }
</style>

<Section horizontalPadding="none" verticalPadding="none" maxWidth="2xl">
  <div
    class="hero-container min-h-[374px] md:min-h-[776px] w-full bg-no-repeat bg-center bg-cover"
    style="background-image: url({HeroImage});
    "
  >
    <div class="relative hidden md:block">
      <div class="flex justify-end h-full md:h-[584px] items-center">
        <div
          class="w-full md:w-7/12 container text-white py-6 md:p-5 md:rounded-3xl leading-tight"
        >
          <h1
            class="text-48 text-center md:text-left font-extrabold px-4 md:px-0 title"
          >
            Meet FIXD, <br />Your Pocket Mechanic
          </h1>

          <div class="hidden md:block md:w-8/12">
            <p class="py-4 text-lg">
              FIXD instantly translates over 7,000 check engine light problems
              into plain English on your smartphone so you can feel confident at
              the repair shop.
            </p>

            <div class="mt-7">
              <slot name="cta" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Section>

<div class="block md:hidden font-semibold background-container -mt-20 py-5">
  <Section verticalPadding="none" center={true} background="asdf">
    <div class="text-white leading-tight">
      <p class="text-2xl font-extrabold">
        Meet FIXD, <br />Your Pocket Mechanic
      </p>
      <p class="py-4 font-medium text-xl">
        FIXD instantly translates over 7,000 check engine light problems into
        plain English on your smartphone so you can feel confident at the repair
        shop.
      </p>
    </div>

    <slot name="cta" />
  </Section>
</div>
