<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import HeroImage from '@/images/landing-page/scorpio/io/3-step-how-it-works.png'
</script>

<Section
  center="true"
  maxWidth="2xl"
  horizontalPadding="none"
  verticalPadding="none"
>
  <div class="py-11 bg-[#000000] md:px-16">
    <div class="text-white">
      <div class="mb-12">
        <h1 class="font-extrabold mb-6">How It Works</h1>
      </div>
      <img
        src={HeroImage}
        loading="lazy"
        class="w-full"
        alt="3 steps to how it works"
      />
    </div>
  </div>
</Section>
<Section
  center="true"
  maxWidth="2xl"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 bg-[#000000] md:px-16">
    <div class="mt-5 text-white">
      <div class="flex flex-col md:flex-row mb-7 gap-10">
        <div
          class="text-center text-xl mx-6 my-1 md:m-2.5 leading-tight flex flex-col items-center"
        >
          <p class="font-extrabold mb-6">Step 1: Plug in FIXD</p>

          <p class="font-medium">
            FIXD plugs into your car's OBD2 port (every car made since 1996 has
            one), usually located under the steering wheel.
          </p>
        </div>
        <div
          class="text-center text-xl mx-6 my-1 md:m-2.5 leading-tight flex flex-col items-center"
        >
          <p class="font-extrabold mb-6">Step 2: Start your car</p>

          <p class="font-medium">
            Download the FIXD app and start your engine. The FIXD Sensor easily
            connects to your smartphone via Bluetooth.
          </p>
        </div>
        <div
          class="text-center text-xl mx-6 my-1 md:m-2.5 leading-tight flex flex-col items-center"
        >
          <p class="font-extrabold mb-6">Step 3: Tap to scan!</p>

          <p class="font-medium">
            Hit the "scan" button on your phone to instantly see what your check
            engine light means, how serious it is, and how to fix it.
          </p>
        </div>
      </div>
      <div class="flex justify-center w-full mt-20">
        <slot name="cta" />
      </div>
    </div>
  </div>
</Section>
