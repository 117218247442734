<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import phoneImg from '@/images/landing-page/Sensor67/benefits.gif'

  import {
    Wrench,
    Engine,
    UserIcon,
    CalendarBell,
    CarConnect,
    Card,
  } from '@/components/icons'
</script>

<Section maxWidth="xl">
  <div class="xl:px-14">
    <div class="leading-tight text-center max-w-screen-md mx-auto mb-6">
      <h2 class="mb-7 md:mb-1">
        Here's why FIXD is more than just another code reader
      </h2>
      <p class="font-medium">
        Unlike other OBD2 scanners, FIXD doesn't just spit out the definition of
        your code. It tells you in simple terms what your check engine light
        means, how serious it is, and ways to fix it. Plus, it's packed with
        powerful features that will boost your confidence at the shop and save
        you money!
      </p>
    </div>
    <div
      class="text-center md:text-left flex flex-col md:flex-row flex-nowrap items-center"
    >
      <div class="flex flex-col flex-nowrap basis-full px-2.5 lg:pr-3">
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <UserIcon color="green" size="2xl" class="size-12" />
            <div>
              <h3 class="text-lg m-0 font-extrabold">Easy to use</h3>
              <p class="font-semibold mt-1">
                Translates over 7000 issues into easy-to-understand terms, right
                on your phone. No car knowledge needed.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <Engine color="yellow" size="2xl" class="size-12" />
            <div>
              <h3 class="text-lg m-0 font-extrabold">Instantly Clear Codes</h3>
              <p class="font-semibold mt-1">
                Clear check engine lights with the touch of a button and keep a
                record of past vehicle scans inside the FIXD app.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <Card color="#C2185B" size="2xl" class="size-12" />
            <div>
              <h3 class="text-lg m-0 font-extrabold">Never Overpay Again</h3>
              <p class="font-semibold mt-1">
                Get detailed information you can take straight to the mechanic
                to ensure you're getting a fair deal.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex flex-col flex-nowrap basis-full px-2.5 lg:px-3 self-start md:self-center"
      >
        <img
          src={phoneImg}
          alt="The FIXD app and sensor"
          class="mx-auto w-full md:w-3/4 lg:w-full mx-auto"
        />
      </div>

      <div class="flex flex-col flex-nowrap basis-full px-2.5 lg:pl-3">
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <CalendarBell color="navy" size="2xl" class="size-14" />
            <div>
              <h3 class="text-lg m-0 font-extrabold">Maintenance Alerts</h3>
              <p class="font-semibold mt-1">
                FIXD automatically alerts you when your vehicle is due for
                service so you can save on costly repairs and keep it running
                smoothly.
              </p>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <Wrench color="red" size="2xl" class="size-14" />
            <div>
              <h3 class="text-lg m-0 font-extrabold">
                Prevent Costly Breakdowns
              </h3>
              <p class="font-semibold mt-1">
                Monitor all your vehicles from one account, making it easy to
                stay on top of car health and avoid costly repairs.
              </p>
            </div>
          </div>
        </div>
        <div class="mb-5">
          <div class="flex flex-row flex-nowrap gap-4 mb-2.5 text-left">
            <CarConnect color="#009688" size="3xl" class="size-14" />
            <div>
              <h3 class="text-lg m-0 font-extrabold">Real-Time Monitoring</h3>
              <p class="font-semibold mt-1">
                Continuously monitor your vehicle's performance, easily
                troubleshoot systems, and stress less with live engine data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</Section>
