<script>
  import HeroImage from '@/images/landing-page/scorpio/io/hero-xl.jpg'
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import {
    Logo,
    FixdLogoText,
    FaStarFilledRound,
    Chevron,
  } from '@/components/icons'

  export let completedQuiz
  export let startedQuiz

  function* range(start, end, step = 1) {
    let state = start
    while (state <= end) {
      yield state
      state += step
    }
  }

  const years = Array.from(range(1950, new Date().getFullYear() + 1)).reverse()

  const makes = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Karma',
    'Kia',
    'Lamborghini ',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Porsche',
    'Ram',
    'Smart',
    'Subaru',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]
  const fuelTypes = ['Gas', 'Hybrid', 'Diesel', 'Electric']

  let year = null
  let make = null
  let fuelType = null

  $: compatible = isCompatible(year, make, fuelType)

  function isCompatible(year, make, fuelType) {
    if (year !== null || make !== null || fuelType !== null) {
      startedQuiz = true
    }
    if (year === null || make === null || fuelType === null) return null
    if (year < 1996) return false
    if (fuelType === 'Diesel' && year < 2006) return false
    if (fuelType === 'Electric') return false
    completedQuiz = true
    return true
  }

  const resetForm = () => {
    compatible = null
    year = null
    make = null
    fuelType = null
  }
</script>

<style>
  .title {
    text-shadow: 4px 4px 8.4px rgb(0 0 0 / 67%);
  }

  .container {
    background: rgb(0 0 0 / 50%);
  }

  select {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .hero-container {
    margin: auto;
    height: calc(100vw * 0.4);
  }

  @media only screen and (width >= 768px) {
    .title {
      text-shadow: none;
    }

    .hero-container {
      margin: auto;
      height: calc(75vw * 0.6);
    }
  }
</style>

<div class="compatibility">
  <div
    class="hero-container w-full bg-no-repeat bg-center bg-cover w-screen lg:w-[75vw] min-h-[375px]"
    style="background-image: url({HeroImage});
  "
  >
    <Section horizontalPadding="none" verticalPadding="none" maxWidth="2xl">
      <div class="flex items-center gap-2 justify-center mb-10 pt-4">
        <Logo color="white" size="sm" />
        <FixdLogoText color="white" size="sm" />
      </div>

      <div
        class="w-full h-full container lg:w-1/2 text-white pb-10 pt-14 lg:px-5 lg:py-6 m-auto rounded-3xl lg:ml-[10%] lg:mt-[4vw] 2xl:mt-[15%]"
      >
        {#if compatible === null}
          <div>
            <h1
              class="text-40 text-center lg:text-left font-semibold px-4 lg:px-0 title leading-[42px] mb-2"
            >
              Will FIXD work with my car?
            </h1>
            <div class="hidden lg:block">
              <p class="font-semibold mb-4">
                Have you always wondered if America's favorite car gadget works
                with your car? Every gas-powered vehicle since 1996 and every
                diesel since 2008 work with FIXD. See for yourself today.
              </p>

              <div class="inline-flex items-center">
                {#each new Array(5) as _}
                  <FaStarFilledRound color="white" size="md" />
                {/each}
                <p class="pl-2 mt-1">50,000+ Reviews</p>
              </div>

              <form class="flex gap-4 mt-6">
                <div class="flex-1">
                  <div class="form-control">
                    <select
                      bind:value={year}
                      name="year"
                      class="no-label bg-white text-black"
                    >
                      <option value={null}>Year</option>
                      {#each years as year}
                        <option value={year}>{year}</option>
                      {/each}
                    </select>
                    <div class="absolute right-4 top-1.5 pointer-events-none">
                      <Chevron color="black" size="xs" />
                    </div>
                  </div>
                </div>

                <div class="flex-1">
                  <div class="form-control">
                    <select
                      bind:value={make}
                      name="make"
                      class="no-label bg-white text-black"
                    >
                      <option value={null}>Make</option>
                      {#each makes as make}
                        <option value={make}>{make}</option>
                      {/each}
                    </select>

                    <div class="absolute right-4 top-1.5 pointer-events-none">
                      <Chevron color="black" size="xs" />
                    </div>
                  </div>
                </div>

                <div class="flex-1">
                  <div class="form-control">
                    <select
                      bind:value={fuelType}
                      name="fuel-type"
                      class="no-label bg-white text-black"
                    >
                      <option value={null}>Fuel type</option>
                      {#each fuelTypes as fuelType}
                        <option value={fuelType}>{fuelType}</option>
                      {/each}
                    </select>

                    <div class="absolute right-4 top-1.5 pointer-events-none">
                      <Chevron color="black" size="xs" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        {:else if compatible}
          <h1
            class="text-40 text-center lg:text-left font-semibold px-4 lg:px-0 title mb-2"
          >
            Yes! FIXD works with <br /> your vehicle.
          </h1>
        {:else}
          <div class="space-y-4 font-semibold text-center md:text-left">
            <h1
              class="text-40 text-center lg:text-left font-semibold px-4 lg:px-0 title mb-2"
            >
              We're sorry.
            </h1>
            <p>We're sorry, FIXD is not compatible with this vehicle.</p>
            <button on:click={resetForm}> Try again </button>

            <div class="hidden md:block">
              <p>
                Luckily we have tons of <a
                  href="https://www.fixdapp.com/"
                  target="_blank"
                  rel="noreferrer"
                  class="underline font-bold text-green"
                  >free car care resources available on our blog</a
                > that we hope you find useful.
              </p>

              <p>Happy driving!</p>
            </div>
          </div>
        {/if}
      </div>
    </Section>
  </div>

  <div class="block lg:hidden font-semibold">
    <Section verticalPadding="none">
      <div class="text-white px-3">
        {#if compatible === null}
          <p class="font-semibold mb-4 mt-3">
            Have you always wondered if America's favorite car gadget works with
            your car? Every gas-powered vehicle since 1996 and every diesel
            since 2008 work with FIXD. See for yourself today.
          </p>

          <div class="inline-flex items-center">
            {#each new Array(5) as _}
              <FaStarFilledRound color="white" size="md" />
            {/each}
            <p class="pl-2 mt-1">50,000+ Reviews</p>
          </div>

          <form class="flex flex-col md:flex-row gap-4 mt-6">
            <div class="flex-1">
              <div class="form-control">
                <select
                  bind:value={year}
                  name="year"
                  class="no-label bg-white text-black"
                >
                  <option value={null}>Year</option>
                  {#each years as year}
                    <option value={year}>{year}</option>
                  {/each}
                </select>
                <div class="absolute right-4 top-1.5 pointer-events-none">
                  <Chevron color="black" size="xs" />
                </div>
              </div>
            </div>

            <div class="flex-1">
              <div class="form-control">
                <select
                  bind:value={make}
                  name="make"
                  class="no-label bg-white text-black"
                >
                  <option value={null}>Make</option>
                  {#each makes as make}
                    <option value={make}>{make}</option>
                  {/each}
                </select>

                <div class="absolute right-4 top-1.5 pointer-events-none">
                  <Chevron color="black" size="xs" />
                </div>
              </div>
            </div>

            <div class="flex-1">
              <div class="form-control">
                <select
                  bind:value={fuelType}
                  name="fuel-type"
                  class="no-label bg-white text-black"
                >
                  <option value={null}>Fuel type</option>
                  {#each fuelTypes as fuelType}
                    <option value={fuelType}>{fuelType}</option>
                  {/each}
                </select>

                <div class="absolute right-4 top-1.5 pointer-events-none">
                  <Chevron color="black" size="xs" />
                </div>
              </div>
            </div>
          </form>
        {:else if !compatible}
          <div class="space-y-4 text-center">
            <p>
              Luckily we have tons of <a
                href="https://www.fixdapp.com/"
                target="_blank"
                rel="noreferrer"
                class="underline font-bold text-green"
                >free car care resources available on our blog</a
              > that we hope you find useful.
            </p>

            <p>Happy driving!</p>
          </div>
        {/if}
      </div>
    </Section>
  </div>
</div>
