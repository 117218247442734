<script>
  import { Logo, FixdLogoText } from '@/components/icons'

  export let darkTheme = true

  export let height = 'small'

  const heightVariants = {
    normal: 'h-[72px]',
    small: 'h-[52px]',
  }
</script>

<style>
  .banner {
    box-shadow: 0 5px 11px 1px rgb(0 0 0 / 25%);
  }
</style>

<div class:bg-black-200={darkTheme} class:banner={!darkTheme}>
  <div
    class="flex items-center m-auto {heightVariants[
      height
    ]} container max-w-[940px] px-5 justify-center"
  >
    <div class="flex items-center gap-2">
      <Logo
        color={darkTheme ? 'white' : 'green'}
        size={height === 'normal' ? 'md' : 'sm'}
      />
      <FixdLogoText
        color={darkTheme ? 'white' : '#3F4147'}
        size={height === 'normal' ? 'md' : 'sm'}
      />
    </div>
  </div>
</div>
