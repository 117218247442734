<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import Phone1 from '@/images/landing-page/scorpio/io/phone10.png'
  import Phone2 from '@/images/landing-page/scorpio/io/phone11.png'
  import Phone3 from '@/images/landing-page/scorpio/io/phone12.png'
  import Phone4 from '@/images/landing-page/scorpio/io/phone13.png'
  import Phone5 from '@/images/landing-page/scorpio/io/phone14.png'
  import Phone6 from '@/images/landing-page/scorpio/io/phone15.png'

  const images = [
    {
      img: Phone1,
      title: 'Know How Much Repairs Should Cost',
      description:
        "See exactly how much repairs should cost so you don't get taken advantage of.",
    },
    {
      img: Phone2,
      title: 'Expert Help on Speed Dial',
      description:
        'Get unbiased, expert advice from our live Mechanic Hotline, available 5 days a week to answer all your car questions.',
    },
    {
      img: Phone3,
      title: '24/7 AI Mechanic Support',
      description:
        "Get instant answers, day or night, with AI Mechanic. It's like having the world's most knowledgeable mechanic at your fingertips.",
    },
    {
      img: Phone4,
      title: 'Predict Future Car Problems',
      description:
        "See what problems your vehicle is most likely to face in the future and how much they'll cost you with Issue Forecast.",
    },
    {
      img: Phone5,
      title: 'Get a Used Vehicle History Report in Seconds',
      description:
        'Find out whether that used car is a great deal or a lemon. Similar reports cost $40 each, but you can access unlimited reports for free.',
    },
    {
      img: Phone6,
      title: 'Test Your Emissions at Home',
      description:
        "Save time and be confident you'll pass emissions the first time with Emissions Precheck!",
    },
  ]
</script>

<Section
  center="true"
  maxWidth="2xl"
  verticalPadding="none"
  horizontalPadding="none"
>
  <div class="py-11 md:px-16">
    <div class="md:flex flex-wrap hidden">
      {#each images as image}
        <div class="basis-1/2 text-center mb-10">
          <img src={image.img} alt={image.title} />
          <div class="my-6">
            <p class="font-bold text-xl">{image.title}</p>
          </div>
          <p class="font-medium text-xl px-10">
            {image.description}
          </p>
        </div>
      {/each}
    </div>

    <div class="md:hidden">
      <Carousel
        showPagination={true}
        loop={true}
        splideOptions={{
          autoHeight: true,
          lazyLoad: true,
        }}
      >
        {#each images as image}
          <CarouselItem>
            <div class="text-center">
              <img src={image.img} alt={image.title} loading="lazy" />
              <div class="text-xl leading-tight mt-10 mx-auto w-full md:w-4/5">
                <p class="font-bold">{image.title}</p>
                <p class="font-medium">
                  {image.description}
                </p>
              </div>
            </div>
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
    <div class="hidden md:block">
      <slot name="cta" />
    </div>
  </div>
</Section>
