<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  import Carousel from '@/components/Carousel.svelte'
  import CarouselItem from '@/components/CarouselItem.svelte'

  import ImageOne from '@/images/landing-page/scorpio/L017/cellphone1.png'
  import ImageTwo from '@/images/landing-page/scorpio/L017/cellphone2.png'
  import ImageThree from '@/images/landing-page/scorpio/L017/cellphone3.png'
  import ImageFour from '@/images/landing-page/scorpio/L017/cellphone4.png'
  import ImageFive from '@/images/landing-page/scorpio/L017/cellphone5.png'
  import ImageSix from '@/images/landing-page/scorpio/L017/cellphone6.png'
  import ImageSeven from '@/images/landing-page/scorpio/L017/cellphone7.png'

  const images = [
    { url: ImageOne, altText: 'Cost and Fix' },
    { url: ImageTwo, altText: 'Tire Deflation' },
    { url: ImageThree, altText: 'Multi Vehicles' },
    { url: ImageFour, altText: 'Maintenance Timeline' },
    { url: ImageFive, altText: 'Wipers Report' },
    { url: ImageSix, altText: 'Cost of Repair' },
    { url: ImageSeven, altText: 'Vehicle Valuation' },
  ]
</script>

<Section center={true}>
  <div class="flex items-center flex-col md:flex-row">
    <div class="basis-1/2">
      <div class="md:w-4/5 mx-auto">
        <Carousel showPagination={false} showArrows={true} arrowSize="lg">
          {#each images as image}
            <CarouselItem>
              <div class="flex items-center justify-center h-full">
                <img src={image.url} alt={image.alt} />
              </div>
            </CarouselItem>
          {/each}
        </Carousel>
      </div>
    </div>
    <div class="basis-1/2 mt-10 md:mt-0">
      <div class="text-left md:w-4/5">
        <p class="text-green font-extrabold">
          But that's just the beginning...
        </p>
        <h2 class="font-extrabold">Here's what else FIXD can do for you:</h2>

        <div class="mt-4">
          <ul class="font-medium leading-tight px-5 list-disc">
            <li>
              <span class="font-bold"
                >Get the value of any used car in seconds -
              </span> think if KBB factored in vehicle condition
            </li>
            <li>
              <span class="font-bold"
                >Get help from the FIXD AI Mechanic 24/7 -
              </span> troubleshooting, repairs, and maintenance are no match for
              you anymore!
            </li>
            <li>
              <span class="font-bold"
                >Forecast likely problems before they happen to your car...</span
              > so you can budget for them or sell to avoid them!
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</Section>
