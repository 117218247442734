<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  function* range(start, end, step = 1) {
    let state = start
    while (state <= end) {
      yield state
      state += step
    }
  }

  const years = Array.from(range(1950, new Date().getFullYear() + 1)).reverse()

  const makes = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Karma',
    'Kia',
    'Lamborghini ',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Porsche',
    'Ram',
    'Smart',
    'Subaru',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]
  const fuelTypes = ['Gas', 'Hybrid', 'Diesel', 'Electric']

  let year = null
  let make = null
  let fuelType = null

  function isCompatible(year, make, fuelType) {
    if (year === null || make === null || fuelType === null) return null
    if (year < 1996) return false
    if (fuelType === 'Diesel' && year < 2006) return false
    if (fuelType === 'Electric') return false
    return true
  }

  $: compatible = isCompatible(year, make, fuelType)

  export let darkTheme = false
</script>

<Section
  center="true"
  maxWidth="xl"
  horizontalPadding="none"
  verticalPadding="none"
>
  <div
    class="{darkTheme ? 'bg-[#000000]' : 'bg-grey-200'} py-11 md:px-16"
    id="compatibilityWidget"
  >
    <slot name="header">
      <h2 class="font-bold text-xl mb-4 md:mb-6">Does FIXD work on my car?</h2>
    </slot>

    <slot name="content" />

    <form class="px-4 flex flex-col md:flex-row gap-2.5">
      <div class="flex-1">
        <div class="form-control bg-white rounded-xl">
          <select
            bind:value={year}
            name="year"
            class="no-label text-center rounded-xl"
            class:bg-black-200={darkTheme}
          >
            <option value={null}>Year</option>
            {#each years as year}
              <option value={year} class="text-black">{year}</option>
            {/each}
          </select>
        </div>
      </div>

      <div class="flex-1">
        <div class="form-control bg-white rounded-xl">
          <select
            bind:value={make}
            name="make"
            class="no-label text-center rounded-xl"
            class:bg-black-200={darkTheme}
          >
            <option value={null}>Make</option>
            {#each makes as make}
              <option value={make} class="text-black">{make}</option>
            {/each}
          </select>
        </div>
      </div>

      <div class="flex-1">
        <div class="form-control bg-white rounded-xl">
          <select
            bind:value={fuelType}
            name="fuel-type"
            class="no-label text-center rounded-xl"
            class:bg-black-200={darkTheme}
          >
            <option value={null}>Fuel type</option>
            {#each fuelTypes as fuelType}
              <option value={fuelType} class="text-black">{fuelType}</option>
            {/each}
          </select>
        </div>
      </div>
    </form>
    <p class="font-medium pt-5">
      {#if compatible === null}
        <!-- Nothing -->
      {:else if compatible}
        <p class="text-green mb-4">
          Yes! FIXD is compatible with your vehicle.
        </p>
        <slot name="compatible" />
      {:else}
        <p class="not-compatible">
          We're sorry, your car is not compatible with FIXD.
        </p>
        <slot name="incompatible" />
      {/if}
    </p>
  </div>
</Section>
