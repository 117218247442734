<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
</script>

<Section
  center="true"
  maxWidth="2xl"
  horizontalPadding="none"
  verticalPadding="none"
>
  <div class="py-9 md:py-20 bg-[#000000] md:px-16">
    <div class="text-white">
      <div class="w-full md:w-4/5 mx-auto px-3">
        <h1 class="font-extrabold mb-3">BONUS</h1>
        <h1 class="leading-normal">
          We're Also Throwing in These Value-Packed Premium Features FREE for 2
          Weeks!
        </h1>
      </div>
    </div>
  </div>
</Section>
