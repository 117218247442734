<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'
  // NOTE: there's a more robust compatibility widget managed by FIXD Rails:
  // https://app.fixdapp.com/app/compatibility

  // This one is a recreation of the one on the LP, which
  // hard-codes a combination of MMYs and has very simple logic

  function* range(start, end, step = 1) {
    let state = start
    while (state <= end) {
      yield state
      state += step
    }
  }

  const years = Array.from(range(1950, new Date().getFullYear() + 1)).reverse()

  const makes = [
    'Acura',
    'Alfa Romeo',
    'Aston Martin',
    'Audi',
    'Bentley',
    'BMW',
    'Buick',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Genesis',
    'GMC',
    'Honda',
    'Hyundai',
    'Infiniti',
    'Jaguar',
    'Jeep',
    'Karma',
    'Kia',
    'Lamborghini ',
    'Land Rover',
    'Lexus',
    'Lincoln',
    'Mazda',
    'McLaren',
    'Mercedes-Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Porsche',
    'Ram',
    'Smart',
    'Subaru',
    'Tesla',
    'Toyota',
    'Volkswagen',
    'Volvo',
  ]
  const fuelTypes = ['Gas', 'Hybrid', 'Diesel', 'Electric']

  let year = null
  let make = null
  let fuelType = null

  function isCompatible(year, make, fuelType) {
    if (year === null || make === null || fuelType === null) return null
    if (year < 1996) return false
    if (fuelType === 'Diesel' && year < 2006) return false
    if (fuelType === 'Electric') return false
    return true
  }

  $: compatible = isCompatible(year, make, fuelType)
</script>

<Section
  center="true"
  maxWidth="2xl"
  horizontalPadding="none"
  verticalPadding="none"
>
  <div class="bg-grey-200 py-11 md:px-16">
    <slot name="header">
      <h2 class="font-semibold mb-4 md:mb-10">
        Wondering If FIXD Works With Your Car? Check Here!
      </h2>
    </slot>

    <slot name="content" />

    <form class="px-4 flex flex-col md:flex-row gap-2.5">
      <div class="flex-1">
        <div class="form-control bg-white rounded-xl">
          <select
            bind:value={year}
            name="year"
            class="no-label text-center rounded-xl"
          >
            <option value={null}>Year</option>
            {#each years as year}
              <option value={year}>{year}</option>
            {/each}
          </select>
        </div>
      </div>

      <div class="flex-1">
        <div class="form-control bg-white rounded-xl">
          <select
            bind:value={make}
            name="make"
            class="no-label text-center rounded-xl"
          >
            <option value={null}>Make</option>
            {#each makes as make}
              <option value={make}>{make}</option>
            {/each}
          </select>
        </div>
      </div>

      <div class="flex-1">
        <div class="form-control bg-white rounded-xl">
          <select
            bind:value={fuelType}
            name="fuel-type"
            class="no-label text-center rounded-xl"
          >
            <option value={null}>Fuel type</option>
            {#each fuelTypes as fuelType}
              <option value={fuelType}>{fuelType}</option>
            {/each}
          </select>
        </div>
      </div>
    </form>
    <p class="font-medium pt-5">
      {#if compatible === null}
        <!-- Nothing -->
      {:else if compatible}
        <p class="text-green mb-4">
          Yes! FIXD is compatible with your vehicle.
        </p>
        <slot name="compatible" />
      {:else}
        <p class="not-compatible">
          We're sorry, your car is not compatible with FIXD.
        </p>
        <slot name="incompatible" />
      {/if}
    </p>
  </div>
</Section>
