<script>
  import Section from '@/components/landing-page/widgets/Section.svelte'

  import { Splide, SplideTrack } from '@splidejs/svelte-splide'

  import LiveDataImage from '@/images/landing-page/scorpio/feature-live-data.png'
  import HistoryImage from '@/images/landing-page/scorpio/feature-history.png'
  import CostBreakdownImage from '@/images/landing-page/scorpio/feature-cost-breakdowns.png'
  import CodeDetailsImage from '@/images/landing-page/scorpio/feature-code-details.png'
  import TapToScanImage from '@/images/landing-page/scorpio/feature-tap-to-scan.png'
  import MaintenanceAlertsImage from '@/images/landing-page/scorpio/feature-maintenance-alerts.png'

  import CarouselItem from '@/components/CarouselItem.svelte'

  import {
    Wrench,
    Engine,
    UserIcon,
    CalendarBell,
    CarConnect,
    Card,
    Chevron,
  } from '@/components/icons'

  const images = [
    TapToScanImage,
    CodeDetailsImage,
    CostBreakdownImage,
    MaintenanceAlertsImage,
    HistoryImage,
    LiveDataImage,
  ]

  $: selectedItemIndex = 0

  let splide = null
  let textSlider = null
</script>

<Section maxWidth="xl" additionalClass="bg-[#3F525C]" background="none">
  <div
    class="leading-tight text-center max-w-screen-md mx-auto mb-6 text-white md:mt-8"
  >
    <h2 class="mb-7 md:mb-1">
      Here's why FIXD is more than just another code reader
    </h2>
    <p class="font-medium">
      Unlike other OBD2 scanners, FIXD doesn't just spit out the definition of
      your code. It tells you in simple terms what your check engine light
      means, how serious it is, and ways to fix it. Plus, it's packed with
      powerful features that will boost your confidence at the shop and save you
      money!
    </p>
  </div>
  <div
    class="text-center md:text-left flex flex-col md:flex-row flex-nowrap lg:px-3"
  >
    <div
      class="hidden md:flex flex-col flex-nowrap basis-full px-2.5 lg:pr-3 justify-around"
    >
      <div
        class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px] hover:opacity-100"
        class:opacity-100={selectedItemIndex === 0}
        class:opacity-50={selectedItemIndex !== 0}
        tabindex={-1}
        role="button"
        on:click={() => splide.go(0)}
        on:keypress={() => splide.go(0)}
      >
        <div class="flex flex-row flex-nowrap gap-4 text-left">
          <UserIcon color="green" size="2xl" class="size-12" />
          <div class="">
            <h3 class="text-xl m-0 font-extrabold">Easy to use</h3>
            <p class="font-semibold mt-1">
              Translates over 7000 issues into easy-to-understand terms, right
              on your phone. No car knowledge needed.
            </p>
          </div>
        </div>
      </div>
      <div
        class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px] hover:opacity-100"
        class:opacity-100={selectedItemIndex === 1}
        class:opacity-50={selectedItemIndex !== 1}
        tabindex={-1}
        role="button"
        on:click={() => splide.go(1)}
        on:keypress={() => splide.go(1)}
      >
        <div class="flex flex-row flex-nowrap gap-4 text-left">
          <Engine color="yellow" size="2xl" class="size-12" />
          <div class="">
            <h3 class="text-xl m-0 font-extrabold">Instantly Clear Codes</h3>
            <p class="font-semibold mt-1">
              Clear check engine lights with the touch of a button and keep a
              record of past vehicle scans inside the FIXD app.
            </p>
          </div>
        </div>
      </div>
      <div
        class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px] hover:opacity-100"
        class:opacity-100={selectedItemIndex === 2}
        class:opacity-50={selectedItemIndex !== 2}
        tabindex={-1}
        role="button"
        on:click={() => splide.go(2)}
        on:keypress={() => splide.go(2)}
      >
        <div class="flex flex-row flex-nowrap gap-4 text-left">
          <Card color="#C2185B" size="2xl" class="size-12" />
          <div class="">
            <h3 class="text-xl m-0 font-extrabold">Never Overpay Again</h3>
            <p class="font-semibold mt-1">
              Get detailed information you can take straight to the mechanic to
              ensure you're getting a fair deal.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="self-center md:w-1/3">
      <Splide
        hasTrack={false}
        bind:this={splide}
        on:move={(index) => {
          selectedItemIndex = index.detail.index
          textSlider.go(index.detail.index)
        }}
        options={{
          focus: 'center',
          trimSpace: false,
          autoWidth: true,
          perPage: 3,
          pagination: true,
          arrows: false,
          mediaQuery: 'min',
          flickPower: 150,
          classes: {
            page: `pagination-button`,
            prev: 'splide__arrow--prev splide-arrow-prev',
            next: 'splide__arrow--next splide-arrow-next',
          },
          breakpoints: {
            768: {
              perPage: 3,
              pagination: false,
              autoWidth: false,
              fixedWidth: '275px',
            },
          },
        }}
      >
        <div>
          <div class="items-center relative">
            <div
              role="button"
              class="flex md:hidden absolute z-50 top-[50%] left-0 shrink-0 inline-display items-center justify-center size-10 rounded-full bg-black opacity-70"
              tabindex={-1}
              on:click={() => splide.go('<')}
              on:keypress={() => splide.go('<')}
            >
              <Chevron direction="left" class="mr-1" color="white" />
            </div>
            <SplideTrack>
              {#each images as item, index}
                <CarouselItem>
                  <div
                    class="flex items-center h-full justify-center transition-all"
                  >
                    <img
                      src={item}
                      alt="name"
                      class="transition-all {selectedItemIndex === index
                        ? 'h-full'
                        : 'h-[90%]'}"
                      class:opacity-100={selectedItemIndex === index}
                      class:opacity-50={selectedItemIndex !== index}
                    />
                  </div>
                </CarouselItem>
              {/each}
            </SplideTrack>

            <div
              role="button"
              class="flex absolute md:hidden top-[50%] z-50 right-0 shrink-0 inline-display items-center justify-center size-10 rounded-full bg-black opacity-70"
              tabindex={-1}
              on:click={() => splide.go('>')}
              on:keypress={() => splide.go('>')}
            >
              <Chevron direction="right" class="ml-1" color="white" />
            </div>

            <div class="my-4">
              <ul class="splide__pagination splide-pagination" />
            </div>
          </div>

          <div class="items-center gap-4 justify-center mt-6 hidden md:flex">
            <div
              role="button"
              class="flex inline-display items-center justify-center white size-10 rounded-full bg-white"
              tabindex={-1}
              on:click={() => splide.go('<')}
              on:keypress={() => splide.go('<')}
            >
              <Chevron direction="left" class="mr-1" />
            </div>
            <div
              role="button"
              class="flex inline-display items-center justify-center white size-10 rounded-full bg-white"
              tabindex={-1}
              on:click={() => splide.go('>')}
              on:keypress={() => splide.go('>')}
            >
              <Chevron direction="right" class="ml-1" />
            </div>
          </div>
        </div>
      </Splide>

      <div class="w-full max-w-[360px] mx-auto mt-8 md:hidden">
        <Splide
          hasTrack={false}
          bind:this={textSlider}
          options={{
            gap: '4px',
            drag: false,
            pagination: false,
            arrows: false,
            focus: 'center',
            perPage: 1,
            trimSpace: false,
          }}
        >
          <SplideTrack>
            <CarouselItem>
              <div class="rounded-3xl bg-white py-3 px-4">
                <div class="flex flex-row flex-nowrap gap-4 text-left">
                  <UserIcon color="green" size="2xl" class="size-12" />
                  <div class="">
                    <h3 class="text-xl m-0 font-extrabold">Easy to use</h3>
                    <p class="font-semibold mt-1">
                      Translates over 7000 issues into easy-to-understand terms,
                      right on your phone. No car knowledge needed.
                    </p>
                  </div>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px]">
                <div class="flex flex-row flex-nowrap gap-4 text-left">
                  <Engine color="yellow" size="2xl" class="size-12" />
                  <div class="">
                    <h3 class="text-xl m-0 font-extrabold">
                      Instantly Clear Codes
                    </h3>
                    <p class="font-semibold mt-1">
                      Clear check engine lights with the touch of a button and
                      keep a record of past vehicle scans inside the FIXD app.
                    </p>
                  </div>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px]">
                <div class="flex flex-row flex-nowrap gap-4 text-left">
                  <Card color="#C2185B" size="2xl" class="size-12" />
                  <div class="">
                    <h3 class="text-xl m-0 font-extrabold">
                      Never Overpay Again
                    </h3>
                    <p class="font-semibold mt-1">
                      Get detailed information you can take straight to the
                      mechanic to ensure you're getting a fair deal.
                    </p>
                  </div>
                </div>
              </div>
            </CarouselItem>

            <CarouselItem>
              <div class="rounded-3xl bg-white py-3 px-4">
                <div class="flex flex-row flex-nowrap gap-4 text-left">
                  <CalendarBell color="navy" size="2xl" class="size-14" />
                  <div class="">
                    <h3 class="text-xl m-0 font-extrabold">
                      Maintenance Alerts
                    </h3>
                    <p class="font-semibold mt-1">
                      FIXD automatically alerts you when your vehicle is due for
                      service so you can save on costly repairs and keep it
                      running smoothly.
                    </p>
                  </div>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="rounded-3xl bg-white py-3 px-4">
                <div class="flex flex-row flex-nowrap gap-4 text-left">
                  <Wrench color="red" size="2xl" class="size-14" />
                  <div class="">
                    <h3 class="text-xl m-0 font-extrabold">
                      Prevent Costly Breakdowns
                    </h3>
                    <p class="font-semibold mt-1">
                      Monitor all your vehicles from one account, making it easy
                      to stay on top of car health and avoid costly repairs.
                    </p>
                  </div>
                </div>
              </div>
            </CarouselItem>
            <CarouselItem>
              <div class="rounded-3xl bg-white py-3 px-4">
                <div class="flex flex-row flex-nowrap gap-4 text-left">
                  <CarConnect color="#009688" size="3xl" class="size-14" />
                  <div class="">
                    <h3 class="text-xl m-0 font-extrabold">
                      Real-Time Monitoring
                    </h3>
                    <p class="font-semibold mt-1">
                      Continuously monitor your vehicle's performance, easily
                      troubleshoot systems, and stress less with live engine
                      data.
                    </p>
                  </div>
                </div>
              </div>
            </CarouselItem>
          </SplideTrack>
        </Splide>
      </div>
    </div>
    <div
      class="hidden md:flex flex-col flex-nowrap basis-full px-2.5 lg:pl-3 justify-between gap-7"
    >
      <div
        class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px] hover:opacity-100"
        class:opacity-100={selectedItemIndex === 3}
        class:opacity-50={selectedItemIndex !== 3}
        tabindex={-1}
        role="button"
        on:click={() => splide.go(3)}
        on:keypress={() => splide.go(3)}
      >
        <div class="flex flex-row flex-nowrap gap-4 text-left">
          <CalendarBell color="navy" size="2xl" class="size-14" />
          <div class="">
            <h3 class="text-xl m-0 font-extrabold">Maintenance Alerts</h3>
            <p class="font-semibold mt-1">
              FIXD automatically alerts you when your vehicle is due for service
              so you can save on costly repairs and keep it running smoothly.
            </p>
          </div>
        </div>
      </div>

      <div
        class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px] hover:opacity-100"
        class:opacity-100={selectedItemIndex === 4}
        class:opacity-50={selectedItemIndex !== 4}
        tabindex={-1}
        role="button"
        on:click={() => splide.go(4)}
        on:keypress={() => splide.go(4)}
      >
        <div class="flex flex-row flex-nowrap gap-4 text-left">
          <Wrench color="red" size="2xl" class="size-14" />
          <div class="">
            <h3 class="text-xl m-0 font-extrabold">
              Prevent Costly Breakdowns
            </h3>
            <p class="font-semibold mt-1">
              Monitor all your vehicles from one account, making it easy to stay
              on top of car health and avoid costly repairs.
            </p>
          </div>
        </div>
      </div>
      <div
        class="rounded-3xl bg-white py-3 px-4 md:max-w-[380px] hover:opacity-100"
        class:opacity-100={selectedItemIndex === 5}
        class:opacity-50={selectedItemIndex !== 5}
        tabindex={-1}
        role="button"
        on:click={() => splide.go(5)}
        on:keypress={() => splide.go(5)}
      >
        <div class="flex flex-row flex-nowrap gap-4 text-left">
          <CarConnect color="#009688" size="3xl" class="size-14" />
          <div class="">
            <h3 class="text-xl m-0 font-extrabold">Real-Time Monitoring</h3>
            <p class="font-semibold mt-1">
              Continuously monitor your vehicle's performance, easily
              troubleshoot systems, and stress less with live engine data.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</Section>
