<!-- This button will smooth scroll the user to the BuyBox, which means
it assumes the BuyBox is somewhere on the page. -->
<script>
  export let size = 'small'
  export let scrollToBuyBox = true
  export let checkoutURL = ''

  let loading = false
  function jumpToBuyBox() {
    document.querySelector('.buy-box').scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }
  const linkToCheckout = () => {
    loading = true
    window.location.href = checkoutURL
  }
</script>

<style>
  button {
    border-radius: 60px;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }

  button.medium,
  button.small {
    width: auto;
  }

  @media only screen and (width >= 575px) {
    button {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    button.medium {
      width: 53%;
    }

    button.small {
      width: 32%;
    }
  }
</style>

<button
  on:click={() => (scrollToBuyBox ? jumpToBuyBox() : linkToCheckout())}
  class="buy"
  class:disabled={loading}
  class:medium={size === 'medium'}
  class:small={size === 'small'}
  disabled={loading}
>
  <slot />
</button>
